import {
  DashboardOutlined,
  ShoppingCartOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "categories",
    path: `${APP_PREFIX_PATH}/categories/level1`,
    title: "Categories",
    icon: ShoppingCartOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "sellers",
    path: `${APP_PREFIX_PATH}/sellers`,
    title: "Sellers",
    icon: ShopOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "orders",
    path: `${APP_PREFIX_PATH}/orders`,
    title: "Orders",
    icon: ShopOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "customers",
    path: `${APP_PREFIX_PATH}/customers`,
    title: "Customers",
    icon: ShopOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "cart-items",
    path: `${APP_PREFIX_PATH}/cart-items`,
    title: "Cart Items",
    icon: ShopOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
